<template>
  <div class="is-flex">
    <template v-if="loaded">
      <div class="flex-grow grid column-gap-1 has-4-columns">

        <!-- Search -->
        <text-input
          :disabled="isLoadingData"
          :debounce="2000"
          :placeholder="`${Naming.Asset} Code, ${Naming.CustomField}`"
          :value="filters.search"
          @input="search($event)"
          left-icon="search"
          :label="false">
          <action-button
            v-if="filters.search"
            slot="right"
            @click="clearFilter('search')">
            <icon icon="times"/>
          </action-button>
        </text-input>

        <!-- Status -->
        <data-selector
          searchable
          v-if="!isHidden('status')"
          :value="filters.defective"
          prompt-label="Filter by Status"
          :items="assetStatuses"
          @input="filterBy('defective', $event)">
          <action-button
            v-if="filters.defective"
            slot="right"
            @click="clearFilter('defective')">
            <icon icon="times"/>
          </action-button>
        </data-selector>

        <!-- Asset Type -->
        <data-selector
          v-if="useAssetType && !isHidden('type')"
          searchable
          :value="filters.asset_type_id"
          value-key="id"
          label-key="name"
          prompt-label="Filter by Type"
          :items="assetTypeList"
          @input="filterBy('asset_type_id', $event)">
          <action-button
            v-if="filters.asset_type_id"
            slot="right"
            @click="clearAssetTypeFilter">
            <icon icon="times"/>
          </action-button>
        </data-selector>

        <!-- Onboard Date -->
        <date-picker
          placeholder="Filter by Onboard Date (range)"
          mode="range"
          left-icon="calendar"
          :label="false"
          ref="onboardDate"
          :value="filters.created_at"
          v-if="!isHidden('date')"
          @input="filterOnboardDate">
          <action-button
            v-if="filters.created_at"
            slot="right"
            @click="clearFilter('created_at')">
            <icon icon="times"/>
          </action-button>
        </date-picker>

        <data-selector
            v-if="useClient && !isHidden('client')"
            searchable
            v-model="filters.client"
            @input="runClientFilter"
            left-icon="building"
            value-key="id"
            label-key="legal_name"
            :prompt-label="`Filter by ${Naming.Client}`"
            :items="clientList">
          <data-selector-client-slot
              slot="item"
              slot-scope="{ item }"
              :client="item"/>
          <action-button
              v-if="filters.client"
              slot="right"
              @click="clearClientFilter">
            <icon icon="times"/>
          </action-button>
        </data-selector>

        <div v-show="filters.client">
          <data-selector
            v-if="useSite && !isHidden('site')"
            searchable
            v-model="filters.site"
            @input="runFilters"
            left-icon="building"
            value-key="id"
            label-key="name"
            :prompt-label="`Filter by ${Naming.Site}`"
            :items="siteLocalList">
            <data-selector-site-slot
              slot="item"
              slot-scope="{ item }"
              :site="item"/>
            <action-button
              v-if="filters.site"
              slot="right"
              @click="clearSiteFilter">
              <icon icon="times"/>
            </action-button>
          </data-selector>
        </div>
        <div v-show="filters.site">
          <data-selector
              searchable
              v-model="filters.zone"
              @input="runFilters"
              left-icon="building"
              value-key="id"
              label-key="name"
              :prompt-label="`Filter by ${Naming.Zone}`"
              :items="zoneList">
            <data-selector-site-slot
                slot="item"
                slot-scope="{ item }"
                :site="item"/>
            <action-button
                v-if="filters.zone"
                slot="right"
                @click="clearFilter('zone')">
              <icon icon="times"/>
            </action-button>
          </data-selector>
        </div>

        <data-selector
          v-model="filters.risk"
          v-if="!isHidden('risk')"
          @input="runFilters"
          left-icon="building"
          value-key="id"
          label-key="level"
          prompt-label="Filter by Risk Level"
          :items="riskLevelList">
          <data-selector-risk-slot
            slot="item"
            slot-scope="{ item }"
            :risk="item"
          />
          <action-button
            v-if="filters.risk"
            slot="right"
            @click="clearFilter('risk')">
            <icon icon="times"/>
          </action-button>
        </data-selector>

        <data-selector
          v-model="filters.checklist"
          v-if="!isHidden('checklist') && !filters.last_inspection"
          @input="runFilters"
          left-icon="check"
          value-key="id"
          label-key="name"
          prompt-label="Filter by Last Inspection Checklist"
          :items="checklistList">
          <data-selector-checklist-slot
            slot="item"
            slot-scope="{ item }"
            :checklist="item"
          />
          <action-button
            v-if="filters.checklist"
            slot="right"
            @click="clearFilter('checklist')">
            <icon icon="times"/>
          </action-button>
        </data-selector>

        <data-selector
          v-model="filters.dynamic_status"
          v-if="filters.asset_type_id || !useAssetType"
          @input="runFilters"
          left-icon="clipboard-list"
          value-key="id"
          label-key="name"
          prompt-label="Filter by Dynamic Status"
          :items="dynamicStatuses">
          <data-selector-dynamic-status-slot
            slot="item"
            slot-scope="{ item }"
            :status="item"
          />
          <action-button
            v-if="filters.dynamic_status"
            slot="right"
            @click="clearDynamicStatusFilter">
            <icon icon="times"/>
          </action-button>
        </data-selector>

        <data-selector
            v-model="filters.active_dynamic_status"
            v-if="filters.dynamic_status && dynamicStatusOptions.length"
            @input="runFilters"
            left-icon="check-circle"
            value-key="id"
            label-key="value"
            prompt-label="Filter by Active Status"
            :items="dynamicStatusOptions">
          <action-button
              v-if="filters.active_dynamic_status"
              slot="right"
              @click="clearFilter('active_dynamic_status')">
            <icon icon="times"/>
          </action-button>
        </data-selector>

        <!-- Last Inspection Date -->
        <date-picker
          placeholder="Filter by Date Last Inspected (range)"
          mode="range"
          left-icon="calendar"
          :label="false"
          ref="lastInspectionDate"
          :value="filters.last_inspection"
          v-if="!isHidden('last_inspection') && !filters.checklist"
          @input="filterLastInspectionDate">
          <action-button
            v-if="filters.last_inspection"
            slot="right"
            @click="clearFilter('last_inspection')">
            <icon icon="times"/>
          </action-button>
        </date-picker>
      </div>
    </template>

    <p v-else class="has-text-cool-blue-text">
      Loading Filters…
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { filtering } from '@/mixins'
import { find } from 'lodash'

export default {

  mixins: [
    filtering,
  ],

  props: {
    useAssetType: {
      type: Boolean,
      default: true,
    },
    useSite: {
      type: Boolean,
      default: true,
    },
    useClient: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Array,
      default: () => ([])
    },
    isLoadingData: {
      type: Boolean,
      default: false
    },
  },

  data: () => ({
    loaded: false,
    siteLocalList: [],
    filters: {
      search: '',
      defective: '',
      asset_type_id: '',
      created_at: '',
      last_inspection: '',
      client: '',
      site: '',
      checklist: '',
      zone: '',
      dynamic_status: '',
      active_dynamic_status: '',
      risk: ''
    },
    assetStatuses: [
      { value: '0', label: 'OK' },
      { value: '1', label: 'Defective' },
    ],
  }),

  async created() {
    await Promise.all([
      this.$store.dispatch('assetType/loadAssetTypeList'),
      this.$store.dispatch('riskLevel/loadRiskLevelList'),
      this.$store.dispatch('checklist/loadChecklistSelectList'),
    ])

    if(this.useClient) this.$store.dispatch('client/loadClientList')
    if(this.useSite) this.$store.dispatch('site/loadDropdownSiteList')

    if (this.useAssetType) {
      const assetTypeId = this.$route.query.asset_type_id
      if (assetTypeId) this.filters.asset_type_id = Number(assetTypeId)
    }

    if(this.filters.asset_type_id || !this.useAssetType) {
      let typeId = this.$route.params.assetType ?? this.filters.asset_type_id
      await this.$store.dispatch('dynamicStatus/loadDynamicStatuses', typeId)
    }

    if(this.filters.dynamic_status) {
      this.filters.dynamic_status = Number(this.filters.dynamic_status)
      this.$store.commit('dynamicStatus/setDynamicStatusOptions', this.filters.dynamic_status)
    }

    this.filters.checklist = this.filters.checklist ? Number(this.filters.checklist) : ''
    this.filters.risk = this.filters.risk ? Number(this.filters.risk) : '';
    this.filters.active_dynamic_status = this.filters.active_dynamic_status ? Number(this.filters.active_dynamic_status) : '';

    this.loaded = true
  },

  methods: {
    clearSiteAndZone() {
      this.filters.site = ''
      this.filters.zone = ''
      this.$store.commit('zone/clearZoneList')
    },
    clearSiteFilter() {
      this.filters.zone = ''
      this.$store.commit('zone/clearZoneList')
      this.clearFilter('site')
    },
    clearClientFilter() {
      this.clearSiteAndZone()
      this.clearFilter('client')
    },
    clearAssetTypeFilter() {
      this.clearFilter('asset_type_id')
      this.clearFilter('dynamic_status')
      this.clearFilter('active_dynamic_status')
    },
    clearDynamicStatusFilter() {
      this.clearFilter('dynamic_status')
      this.clearFilter('active_dynamic_status')
      this.$store.commit('dynamicStatus/clearDynamicStatusOptions')
    },
    runClientFilter() {
      this.clearSiteAndZone()
      this.runFilters()
    },
    filterOnboardDate(range) {
      if (!range) {
        this.clearFilter('created_at')
        return
      }
      this.filterBy('created_at', range)
      this.$refs.onboardDate.picker.redraw()
    },
    filterLastInspectionDate(range) {
      if (!range) {
        this.clearFilter('last_inspection')
        return
      }
      this.filterBy('last_inspection', range)
      this.$refs.lastInspectionDate.picker.redraw()
    },
    isHidden(key) {
      return find(this.hidden, hidden => hidden === key) ? true : false
    },
    addZones(siteId) {
      if(siteId) this.$store.dispatch('zone/loadZoneList', siteId)
    },
    async addSites(clientId) {
      if(clientId){
        await this.$store.dispatch('site/loadClientFilterSiteList', clientId)
        const client = this.clientList.find(item => item.id === clientId)
        if(client && this.clientFilterSiteList) {
          this.siteLocalList = this.clientFilterSiteList
        }
      }
    },
    async addDynamicStatuses(value) {
      this.loaded = false
      await this.$store.dispatch('dynamicStatus/loadDynamicStatuses', value).then(() => this.loaded = true)
    },
    search(event) {
      this.filterBy('search', event)
    }
  },

  computed: {
    ...mapGetters('assetType', [
      'assetTypeList',
    ]),
    ...mapGetters('site', [
      'siteList',
      'clientFilterSiteList'
    ]),
    ...mapGetters('client', [
        'clientList',
    ]),
    ...mapGetters('zone', [
        'zoneList',
    ]),
    ...mapGetters('riskLevel', [
      'riskLevelList',
    ]),
    ...mapGetters('checklist', [
      'checklistList',
    ]),
    ...mapGetters('dynamicStatus', [
      'dynamicStatuses',
      'dynamicStatusOptions'
    ]),
  },

  watch: {
    'filters.site': function (value) {
      this.addZones(value)
    },
    'filters.client': function (value) {
      this.addSites(value)
    },
    'filters.asset_type_id': function (value){
      if (value) {
        this.addDynamicStatuses(value)
      }
    },
    'filters.dynamic_status': function (value){
      this.$store.commit('dynamicStatus/clearDynamicStatusOptions')
      if (value) {
        this.$store.commit('dynamicStatus/setDynamicStatusOptions', value)
      }
    },
  }

}
</script>

<style scoped>
.box {
  z-index: 5;
}
</style>
